import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Anywho studios", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", null, " A company focusing mainly on making a variety of different apps and web pages. Most apps are either utilitarian or different type of games ", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, [
      _createTextVNode(" See "),
      _createElementVNode("a", { href: "work" }, "/work"),
      _createTextVNode(" for detailed information about the different apps ")
    ], -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, "Contact: info@anywhostudios.com", -1))
  ], 64))
}