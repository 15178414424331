<template>
  <h1>Anywho studios</h1>

  <p>
    A company focusing mainly on making a variety of different apps and web
    pages. Most apps are either utilitarian or different type of games
  </p>
  <p>
    See <a href="work">/work</a> for detailed information about the different
    apps
  </p>

  <p>Contact: info@anywhostudios.com</p>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class HomeView extends Vue {}
</script>

<style lang="scss">
@import "../variables.scss";
a {
  color: $link-color;
  font-weight: 600;
  text-decoration: none;
}
</style>
