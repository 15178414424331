<template>
  <div>
    <Markdown :source="source" />
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import "highlight.js/styles/atom-one-dark.css";

export default {
  components: {
    Markdown,
  },
  data() {
    return {
      source:
        "\n# Hello World!\n\nSome more text\n* Item 1\n * Item 2\n```javascript\nlet i = 1;\nconsole.log(i);\n```",
    };
  },
};
</script>
