<template>
  <div class="container">
    <div class="main-content">
      <router-view />
    </div>

    <div class="sidebar">
      <img src="@/assets/output.png" alt="My PNG Image" class="my-image" />

      <div class="button">
        <i class="fas fa-chevron-right"></i>
      </div>

      <nav>
        <router-link to="/">Home</router-link><br />
        <router-link to="/work">Work</router-link><br />
        <router-link to="/posts">Posts</router-link><br />
      </nav>
    </div>
  </div>
</template>

<style lang="scss">
@import "./variables.scss";

#app {
  font-family: "Consolas", "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
  font-size: 18px;
  line-height: 27.2px;
  background-color: $background-color;
}
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 280px;
  height: 100%;
  background-color: $sidebar-background-color;
  color: $sidebar-text-color;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.sidebar .logo {
  color: $primary-color;
}
.sidebar .button {
  background: $primary-color;
  color: $secondary-color;
  width: 60px;
  height: 100px;
  position: absolute;
  left: -40px;
  padding-top: 60px;
  padding-left: 4px;
  font-size: 36px;
  border-radius: 10px; /* Add this line to create rounded corners */
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: $sidebar-text-color;
    text-decoration: none; /* Add this line to remove underlining */

    &.router-link-exact-active {
      color: $primary-color;
    }
  }
}

.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.main-content {
  margin-left: 80px;
  margin-right: 360px;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
</style>
